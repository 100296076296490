import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Expo, TweenMax } from "gsap"
import React, { useRef } from "react"
import { animated, useSpring } from "react-spring"
import Masonry from "../components/masonry"
import { vBandWidth } from "../components/page_layout"
import SEO from "../components/seo"

const Tile = ({ src, fluid, index, onImgClick }) => {
  const [props] = useSpring(() => ({
    opacity: 1,
    transform: "scale(1)",
    //color: 'white',
    from: { opacity: 0, transform: "scale(0.9)", cursor: "pointer" },
    delay: 1000 + index * 50,
    transition: "300ms all",
  }))

  const imgref = useRef()

  //return <animated.div style={props}>This text Faded in Using Hooks</animated.div>

  return (
    <animated.div
      className="tile"
      style={props}
      onClick={() =>
        imgref &&
        imgref.current &&
        imgref.current.imageRef &&
        imgref.current.imageRef.current &&
        onImgClick &&
        onImgClick(imgref.current.imageRef.current)
      }
    >
      {/* <img src={src} /> */}
      <Img ref={imgref} fluid={fluid} />
    </animated.div>
  )
}

export default class GalleryPage extends React.Component {
  animDuration = 1
  isAnimating = false
  easing = Expo.easeInOut

  closeImage(imgEl, fullimg, fullimg_closer, fullimg_closer_x) {
    if (this.isAnimating) return
    this.isAnimating = true

    const rectTo = imgEl.getBoundingClientRect()
    const rectFrom = fullimg.getBoundingClientRect()

    TweenMax.to(fullimg_closer, this.animDuration, { opacity: 0 })
    TweenMax.to(fullimg_closer_x, this.animDuration, { opacity: 0 })

    TweenMax.fromTo(
      fullimg,
      this.animDuration,
      {
        margin: 0,
        x: rectFrom.left,
        y: rectFrom.top,
      },
      {
        x: rectTo.left,
        y: rectTo.top,
        width: rectTo.width,
        height: rectTo.height,
        ease: this.easing,
        onComplete: () => {
          this.isAnimating = false
          TweenMax.set(imgEl, { opacity: 1, transition: "none" })
          setTimeout(() => {
            fullimg.remove()
            fullimg_closer.remove()
            fullimg_closer_x.remove()
          }, 50)
        },
      }
    )
  }

  expandImage(imgEl, imgSource, imgSize) {
    if (this.isAnimating) return
    this.isAnimating = true

    // console.log(
    //   imgEl.naturalWidth,
    //   imgEl.naturalHeight,
    //   imgEl.width,
    //   imgEl.height,
    //   imgSize.width,
    //   imgSize.height
    // )

    const rect = imgEl.getBoundingClientRect()

    const onCloseImage = () => {
      this.closeImage(imgEl, fullimg, fullimg_closer, fullimg_closer_x)
    }

    var fullimg_closer_x = document.createElement("DIV")
    fullimg_closer_x.className = "fullimg-close-x"
    fullimg_closer_x.onclick = onCloseImage
    document.body.appendChild(fullimg_closer_x)

    var fullimg_closer = document.createElement("DIV")
    fullimg_closer.onclick = onCloseImage
    document.body.appendChild(fullimg_closer)

    var fullimg = document.createElement("IMG")
    fullimg.src = imgEl.src
    fullimg.onclick = () => {}
    document.body.appendChild(fullimg)

    let width = Math.min(window.innerWidth, imgSize.width)
    let height = Math.min(window.innerHeight, imgSize.height)
    let srcImageLoaded = false

    // image has to resize
    if (
      imgSize.width > window.innerWidth ||
      imgSize.height > window.innerHeight
    ) {
      if (
        imgSize.width / imgSize.height >
        window.innerWidth / window.innerHeight
      ) {
        // resize width
        width = window.innerWidth
        height = width * (imgSize.height / imgSize.width)
      } else {
        // resize height
        height = window.innerHeight
        width = height * (imgSize.width / imgSize.height)
      }
    }

    const swapImage = () => {
      TweenMax.set(fullimg, {
        minWidth: 0,
        minHeight: 0,
        src: imgSource,
      })
    }

    let tmpImage = new Image(imgSize.width, imgSize.height)
    tmpImage.onload = resp => {
      srcImageLoaded = true
      if (!this.isAnimating) {
        swapImage()
      }
    }
    tmpImage.src = imgSource

    TweenMax.set(imgEl, { opacity: 0, delay: 0.05 })

    TweenMax.from(fullimg_closer_x, this.animDuration, { opacity: 0 })

    TweenMax.fromTo(
      fullimg_closer,
      this.animDuration,
      {
        position: "fixed",
        cursor: "crosshair",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 99,
        opacity: 0,
        background: "rgba(0, 0, 0, 0.9)",
      },
      {
        opacity: 1,
      }
    )

    TweenMax.fromTo(
      fullimg,
      this.animDuration,
      {
        position: "fixed",
        left: 0,
        top: 0,
        width: rect.width,
        height: rect.height,
        x: rect.left,
        y: rect.top,
        zIndex: 100,
        margin: 0,
        opacity: 1,
        maxWidth: "100vw",
        maxHeight: "100vh",
      },
      {
        width,
        height,
        x: window.innerWidth / 2 - width / 2,
        y: window.innerHeight / 2 - height / 2,
        ease: this.easing,
        onComplete: () => {
          this.isAnimating = false

          TweenMax.set(fullimg, {
            transform: "none",
            // left: 0,
            // top: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            width: "auto",
            height: "auto",
            minWidth: width,
            minHeight: height,
          })

          if (srcImageLoaded) swapImage()
        },
      }
    )
  }

  render() {
    return (
      <div
        className="gallery-case"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          overflowY: "scroll",
          // marginRight: `-${vBandWidth}px`,
          // paddingRight: `${vBandWidth}px`,
          paddingTop: vBandWidth,
          paddingBottom: vBandWidth * 2,
          zIndex: 1,
          boxSizing: "border-box",
          // opacity: 0.8,
        }}
        //onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <SEO
          title="Gallery"
          keywords={[
            "3dartist",
            "3dmodeling",
            "3d",
            "photorealism",
            "shading",
            "productstudy",
            "3drender",
            "motiongraphics",
            "motiongraphicsdesign",
            "video",
            "videomaking",
            "3danimation",
            "animation",
          ]}
        />

        <StaticQuery
          query={graphql`
            query gallery {
              contentfulGallery { # id: { eq: "6093ff62-71fe-54d3-81d3-3e19b8e31d28" }) # (id: { eq: "010c80e7-b1a4-5612-80e6-ce5455f844ce" }
                images {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          `}
          render={data => (
            <Masonry
              brakePoints={[450, 750, 1000]}
              style={{ overflow: "hidden" }}
            >
              {data &&
                data.contentfulGallery &&
                data.contentfulGallery.images.map((item, index) => (
                  <Tile
                    key={index}
                    index={index}
                    fluid={item.fluid}
                    src={item.file.url}
                    onImgClick={imgEl => {
                      if (window.innerWidth > 450) {
                        this.expandImage(
                          imgEl,
                          item.file.url,
                          item.file.details.image
                        )
                      }
                    }}
                    //src={item.url || "https://static.boredpanda.com/blog/wp-content/uploads/2014/12/Top-10-photographers-for-travel-portraits27__700.jpg"}
                  />
                ))}
            </Masonry>
          )}
        />
      </div>
    )
  }
}
